import Storage from "@/util/Storage";
import {GetUserData} from "@/Api/Basics";


export function getUserInfoSuc() {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    return Promise.resolve( GetUserData({ userId,token }).then(res=>{
        Storage.SetAllData && Storage.SetAllData("UserData",JSON.stringify( res.data ))
        Storage.SetAllData && Storage.SetAllData("OpenId",res.data.openId || '')
        return res.data
    }) )
}
