

import Axios from "@/Api/Config/basics"
import {CodeLoginBodyType, HeaderType, PassLoginBodyType} from "@/Api/Login/indexType";

/** 密码登录 */
export function PassLogin( headers:HeaderType,body:PassLoginBodyType ) {
    return (
        Axios({
            url:"/app/user/login",
            method:"POST",
            headers,
            data:body
        })
    )
}

/** 密码登录 */
export function CodeLogin( headers:HeaderType,body:CodeLoginBodyType ) {
    return (
        Axios({
            url:"/app/user/register",
            method:"POST",
            headers,
            data:body
        })
    )
}
