
import Storage from "@/util/Storage"
import {PassLogin} from "@/Api/Login";
import {PassLoginBodyType} from "@/Api/Login/indexType";
import {Toast} from "vant";
import {getUserInfoSuc} from "@/views/Login/Server";

export function getPassLogin<T=string>(body:PassLoginBodyType) {
    const userId = Storage.GetData_ && Storage.GetData_("userId")
    const token = Storage.GetData_ && Storage.GetData_("token")
    if ( body.phone.length <= 0 ){
        Toast("请输入账号")
        return Promise.resolve(false)
    }
    if ( body.password.length <= 0 ){
        Toast("请输入密码")
        return Promise.resolve(false)
    }
    Toast.loading({ message:"登录中~~",duration:0 })
    return (
        new Promise((resolve, reject) => {
            PassLogin({ userId,token },body).then(res=>{
                let { userId,token } = res.data
                Storage.SetAllData && Storage.SetAllData("userId",userId)
                Storage.SetAllData && Storage.SetAllData("token",token)
                resolve(res.data)
            })
        }).then((res:any)=>{
            return getUserInfoSuc().then(res=>{
                Toast.clear()
                return res
            })
        }).catch(cat=>{
            Toast.clear()
        })
    )
}
